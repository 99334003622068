html, body {
  background: rgb(245, 242, 242);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
  text-align: center;
}

#about-section, 
#projects-section, 
#skills-section, 
#services-section, 
#setup-section, 
#contact-section,
#notfound-section {
  font-size: 1.2rem;
  margin: 40px 0 50px 0;
  padding: 0 50px;
  text-align: left;
}

#about-section a {
  color: #ec091c;
  font-weight: 700;
}

.active {
  border: 3px solid black !important;
  background: #ec091c !important;
  border-radius: 5px;
  color: white !important;
  padding: 5px;
  text-decoration: none;
}

.amatic {
  font-family: 'Amatic SC', cursive;
}

.bold {
  font-weight: bold;
}

.bigger-font {
  font-size: 1.5rem;
}

#canada img {
  height: 40px;
  -webkit-filter: brightness(140%);
  filter: brightness(140%);
}

.checkmark {
  color: mediumseagreen;
  font-size: 1.5rem;
  font-weight: bold;
  padding-left: 2px;
}

.column-two, 
.column-three, 
.column-four, 
.column-five {
  padding-top: 0 !important;
}

.fade-in {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
}

.fade-in-fast {
  animation: fadeIn 200ms;
  -webkit-animation: fadeIn 200ms;
}

.fade-in-slow {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

footer {
  padding: 40px 0 5px 0;
}

.italic {
  font-style: italic;
}

.left {
  float: left;
}

.left-align {
  text-align: left;
}

li {
  padding: 5px 0;
}

#ma-icon img {
  width: 44px;
  margin-top: 10px;
}

.margin-top-bottom {
  margin: 40px 0;
}

#name {
  color: rgb(236, 28, 28);
  font-family: 'Amatic SC', 'Segoe UI', sans-serif;
  font-size: 4.4rem;
  font-weight: 700;
  margin: 10px 0 20px 0;
  letter-spacing: 1px;
  text-shadow: 3px 2px 1px black;
  text-transform: uppercase;
}

#navbar {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.nav-link {
  font-family: 'Share Tech Mono', 'Segoe UI', sans-serif;
  font-size: 1.4rem;
  margin-top: 25px;
  padding: 0 10px;
  text-decoration: none;
}

.nav-link a {
  border: 3px solid black !important;
  border-radius: 5px;
  color: black;
  padding: 5px;
  text-decoration: none;
}

.nav-link a:hover {
  background: black;
  border: 3px solid black !important;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  text-decoration: none;
  padding: 5px;
}

.nav-link a:active, 
.nav-link a:focus {
  outline: none;
}

#notfound-section {
  margin-top: 55px !important;
}

#notfound-title {
  color: red;
  font-size: 2.5rem;
  text-align: center;
}

#notfound-description {
  font-size: 1.5rem;
  text-align: center;
}

.project-border {
  display: block;
  border: 2px solid black;
  margin: 80px auto;
  width: 90%;
}

.project-desc {
  margin: 20px auto;
  text-align: left;
}

.project-desc a {
  color: black;
  font-weight: 700;
}

.project-desc a:hover {
  color: red;
}

.project-image {
  border: 6px solid black;
  border-radius: 15px;
  margin: 10px 0;
  max-width: 99%;
  -webkit-filter: contrast(110%);
  filter: contrast(110%);
}

.project-image-cybrsecure {
  max-width: 40%;
}

.project-git {
  align-items: center;
  display: flex;
}

.project-git a {
  color: black;
}

.project-git a:hover {
  color: red;
  text-decoration: none;
}

.project-git-icon {
  padding-right: 5px;
  width: 28px;
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
}

.project-link {
  font-weight: 500;
  margin-top: 5px;
}

.project-link a {
  color: black;
}

.project-link a:hover {
  color: red;
  text-decoration: none;
}

.red {
  color: red;
}

.relative {
  position: relative !important;
}

.right {
  float: right;
}

.right-align {
  text-align: right;
}

#root {
  background: url('images/bg.webp');
  border-left: 10px solid black;
  border-right: 10px solid black;
  margin: 0 auto;
  width: 60%;
}

section {
  flex-grow: 1;
  flex-shrink: 0;
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.services-list-ul {
  padding-left: 25px;
}

#signature {
  text-align: left;
}

#signature img {
  height: 100px;
}

.sharetech {
  font-family: 'Share Tech Mono', 'Segoe UI', sans-serif;
}

.skills-list-ul {
  padding-left: 25px;
}

#social-media {
  display: flex;
  justify-content: center;
  gap: 2px;
}

.social-icon {
  width: 32px;
  padding: 0 2px;
}

.social-icon:hover {
  -webkit-filter: brightness(140%);
  filter: brightness(140%);
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

ul {
  list-style: square;
  margin-top: 5px;
}

@media screen and (max-width: 400px) {
  #about-section, 
  #projects-section, 
  #skills-section, 
  #services-section, 
  #setup-section, 
  #contact-section,
  #notfound-section {
    font-size: 1rem !important;
  }

  .nav-link a {
    font-size: 0.9rem !important;
  }
}

@media screen and (min-width: 700px) {
  .nav-link a {
    font-size: 1.2rem !important;
  }

  .column-two, 
  .column-three, 
  .column-four, 
  .column-five {
    padding-top: 20px;
  }

  .flex {
    flex-wrap: nowrap !important;
  }

  #name {
    display: inline-block !important;
  }

  .nav-link a {
    margin: 0 5px;
  }
}

@media screen and (max-width: 900px) {
  html, body {
    line-height: 1.5rem;
    border: 3px solid black;
  }

  .App {
    padding: 0 20px;
  }

  #about-section, 
  #projects-section, 
  #skills-section, 
  #services-section, 
  #setup-section, 
  #contact-section,
  #notfound-section {
    font-size: 1.1rem;
    padding: 0 10px;
    text-align: left;
  }

  .checkmark {
    position: absolute;
    margin-top: -3px;
  }

  .column-one, 
  .column-two, 
  .column-three, 
  .column-four, 
  .column-five {
    flex-basis: 100%;
  }

  .flex {
    flex-wrap: wrap;
    gap: 20px;
  }

  footer {
    padding: 10px 0 5px 0;
  }

  li {
    padding: 2px 0;
  }

  #ma-icon img {
    width: 64px;
    margin-bottom: 20px;
  }

  .margin-top-bottom {
    margin: 20px 0;
  }

  #name {
    display: none;
    font-size: 3rem;
    margin: 15px 0 30px 0;
    letter-spacing: 1px;
  }

  #navbar {
    margin-bottom: 0;
  }

  .nav-link {
    font-size: 1rem;
    margin-top: 20px;
    padding: 0 2px;
  }

  #notfound-title {
    font-size: 1.8rem;
  }
  
  #notfound-description {
    font-size: 1.2rem;
  }

  .project-border {
    border: 2px solid black;
    margin: 40px auto;
  }

  .project-image {
    border: 2px solid black;
  }

  .project-image-cybrsecure {
    display: block;
    margin: 40px auto 20px auto;
    max-width: 60%;
  }

  #root {
    border: none;
    width: 100%;
  }

  .section-title {
    font-size: 1.2rem;
  }

  .skills-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .skills-flex li {
    flex: 0 0 50%;
  }

  .social-icon {
    width: 30px;
    padding: 0 4px;
  }
}

@media screen and (max-width: 1200px) {
  .App {
    padding: 0 20px;
  }

  #root {
    border: none;
    width: 100%;
  }
}